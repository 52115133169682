import React from 'react';
import cls from "./invite.module.scss"
import { useTranslation } from 'react-i18next';
import InviteFirst from "../../../assets/invite/InviteFirst.png"
import InviteSecond from "../../../assets/invite/InviteSecond.png"
import InviteThird from "../../../assets/invite/InviteThird.png"
import AppStoreLabelWhite from "../../../assets/AppStoreLabelWhite.svg"
import PlayMarketLabelWhite from "../../../assets/PlayMarketLabelWhite.svg"
import AppStoreLabelFull from "../../../assets/AppStoreLabelFull.svg"
import PlayMarketLabelFull from "../../../assets/PlayMarketLabelFull.svg"

const Invite = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.invite_container}>
      <div className={cls.main_photo}>
        <img className={cls.first_photo} src={InviteFirst} alt='' />
        <img className={cls.second_photo} src={InviteSecond} alt='' />
        <img className={cls.third_photo} src={InviteThird} alt='' />
        <div className={cls.absolute_content}>
          <p className={cls.main_text}>{t('main_text_29')}</p>
          <div className={cls.links_absolute}>
            {/* dirver */}
            <a href="#">
              <img src={AppStoreLabelWhite} alt='' />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.lttaxi.driver">
              <img src={PlayMarketLabelWhite} alt='' />
            </a>
          </div>
        </div>
      </div>
      <div className={cls.links_relative}>
        {/* dirver */}
        <a href="#">
          <img src={AppStoreLabelFull} alt='' />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.lttaxi.driver">
          <img src={PlayMarketLabelFull} alt='' />
        </a>
      </div>
    </div>
  );
}

export default Invite;
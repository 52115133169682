import React from "react";
import { ReactComponent as AppStoreLabel } from "src/assets/AppStoreLabel.svg";
import { ReactComponent as PlayMarketLabel } from "src/assets/PlayMarketLabel.svg";
import { ReactComponent as AppStoreLabelFull } from "src/assets/AppStoreLabelFull.svg";
import { ReactComponent as PlayMarketLabelFull } from "src/assets/PlayMarketLabelFull.svg";
import MobileScreens from "src/assets/MobileScreens.png";
import MobileScreensFull from "src/assets/MobileScreensFull.png";

import style from "./styles.module.scss";
import { t } from "i18next";

const UaView = () => {
  return (
    <div className={style.starter_container}>
      <p className={style.main_text}>{t("main_text_1")}</p>
      <div className={style.main_block}>
        <div className={style.for_links}>
          <div className={style.links}>
            <p className={style.main_text_2}>{t("main_text_2")}</p>
            <p className={style.main_text_3}>{t("main_text_3")}</p>
            <div className={style.donwoload_links}>
              {/* client */}
              <a href="#">
                <AppStoreLabel className={style.unfull_link} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.lttaxi.client">
                <PlayMarketLabel className={style.unfull_link} />
              </a>
              {/* client */}
              <a href="#">
                <AppStoreLabelFull className={style.full_link} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.lttaxi.client">
                <PlayMarketLabelFull className={style.full_link} />
              </a>
            </div>
          </div>
        </div>
        <div className={style.mobile_screens}>
          <img className={style.unfull_mobiles} src={MobileScreens} alt="" />
          <img className={style.full_mobiles} src={MobileScreensFull} alt="" />
        </div>
      </div>
    </div>
  );
};

export default UaView;

import React from 'react';
import cls from "./article.module.scss"
import { useTranslation } from 'react-i18next';
import AppStoreLabelFull from "../../../assets/AppStoreLabelFull.svg"
import PlayMarketLabelFull from "../../../assets/PlayMarketLabelFull.svg"

const Article = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.article_container}>
      <div className={cls.text_block}>
        <p className={cls.main_text}>{t('main_text_26')}</p>
        <p className={cls.second_text}>{t('main_text_27')}</p>
      </div>
      <div className={cls.yellow_block}>
        <p className={cls.text_format}>{t('download_app')}</p>
        <a href="#" style={{ paddingBottom: "24px" }}>
          <img src={AppStoreLabelFull} alt='' />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.lttaxi.client">
          <img src={PlayMarketLabelFull} alt='' />
        </a>
      </div>
    </div>
  );
}

export default Article;